import { Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { PostComponent } from './pages/post/post.component';
import { MetadataComponent } from './pages/metadata/metadata.component';
import { AboutusComponent } from './pages/aboutus/aboutus.component';
export const routes: Routes = [
    { path: '', loadComponent: () => import('./pages/home/home.component').then(c => c.HomeComponent) },
    { path: 'quality-assessment/:id', loadComponent: () => import('./pages/post/post.component').then(c => c.PostComponent) },
    { path: 'metadata', loadComponent: () => import('./pages/metadata/metadata.component').then(c => c.MetadataComponent) },
    { path: 'about-us', loadComponent: () => import('./pages/aboutus/aboutus.component').then(c => c.AboutusComponent) },
    { path: '',   redirectTo: '/', pathMatch: 'full' },
    { path: '**', loadComponent: () => import('./pages/home/home.component').then(c => c.HomeComponent) },
];
